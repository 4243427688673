@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply overflow-hidden;
  }
}

@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .text-shadow-md {
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-lg {
      text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11),
        0 5px 15px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-none {
      text-shadow: none;
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: 100vh;
    height: -webkit-fill-available;
  }
}
